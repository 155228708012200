<template>
    <div>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            :fixed-filters="fixedFilters"
            @update:filters="updateFilters"
        >
            <template #filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template #actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- name column -->
                    <template #[`item.title`]="{ item }">
                        <router-link :to="getEditLink(item)">
                            {{ item.name }}
                        </router-link>
                    </template>

                    <!-- logo column -->
                    <template #[`item.logo`]="{ item }">
                        <v-img
                            :src="item.logo.public_url"
                            height="100"
                            width="100"
                            contain
                        ></v-img>
                    </template>

                    <!-- row actions -->
                    <template #rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>
        </data-iterator>
    </div>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import TableMixin from '@/mixins/Table'
import Headers from '../mixins/Headers'

export default {
    mixins: [TableMixin, Headers],
    data: () => ({
        api: RestApiType.BRANDS,
    }),
    computed: {
        tableName() {
            return this.$t('menu.brands')
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.BRAND_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
